$red: #bd3f3b;
$green: #43984d;
$white: #e6e8ef;
$blue: #0d7ec0;
$darkBg: #1c1e23;
$lightBg: #2a292f;
$mediumGrey: #565656;
$lightGrey: #9e9e9e;
$tsBlue: #518989;
$yellow: #FFCB05;
$numberFont: 'Roboto Condensed', sans-serif;
$normalFont: 'Roboto Condensed', sans-serif;

@font-face {
  font-family: 'TopstepFont';
  src: url('../assets/fonts/SharpGroteskBold16.ttf') format('truetype');
}

@mixin input {
  border: 1px solid $darkBg;
  border-radius: 0.25em;
  padding: 0.5em;
  width: 100%;
  background-color: $lightBg;
  color: $white;
  font-size: 1em;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  &:focus {
    outline: none;
  }
}

@mixin select {
  border: 1px solid $darkBg;
  border-radius: 0.25em;
  padding: 0.5em;
  width: 100%;
  background-color: $darkBg;
  border: 0.1em solid $lightBg;
  color: $white;
  font-size: 1em;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  &:focus {
    outline: none;
  }
}
