@import '/src/styles/variables';

.panelWrapper {
  display: flex;
  overflow: hidden;
}

.panel {
  border-radius: 0.1rem;
  margin: 0.5rem;

  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: $lightBg;
  overflow: hidden;
}

.title {
  padding: 0 0.5em;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-transform: uppercase;
}

.subTitle {
  color: $mediumGrey;
}

.hr {
  width: 100%;
  padding: 0;
  margin: 0;
  border-bottom: 0.1em solid $mediumGrey;
}

.pad {
  padding: 0.5em;
}

.panelContent {
  flex: 1;
  overflow: hidden;
}
