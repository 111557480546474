@import "/src/styles/variables";

.violationBackground {
    width: 100%;
    height: 100%;
    top: 0px;
    position: absolute;
    left: 0px;
    background: #2d2d2dd9;
}

.stopSignBg {
    display: flex;
    align-items: center;
    padding: 6px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #333333;
    &:hover {
        background: lighten(#333333, 10%) !important;
    }
    font-size: 1.4em;
}

.modalHeader {
    font-size: 2em;
    margin-bottom: 1em;
    text-align: center;
}

.modalBody {
    font-size: 1.2em;
}

.drawerFooterText {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: .8em;
    color: #aaaaaa;
    flex-direction: column;
    position: absolute;
    bottom: 0;
}