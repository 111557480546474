.dock {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  display: flex;

  &-bar {
    font-size: 14px;
    line-height: 1.5;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    outline: none;
    zoom: 1;
  }

  &-ink-bar {
    position: absolute;
    box-sizing: border-box;
    margin-top: -3px;
    background-color: $primary-color;
    transform-origin: 0 0;
    width: 0;
    height: 0;
  }


  &-tab-btn-disabled {
    cursor: default;
    color: $disabled-color;
  }

  &-nav-animated {
    transition: transform 0.25s cubic-bezier(0.35, 0, 0.25, 1);
  }


  &-tab {
    box-sizing: border-box;
    position: relative;
    display: block;
    min-width: 30px;
    height: 30px;
    text-align: center;
    transition: color 0.25s cubic-bezier(0.35, 0, 0.25, 1);
    padding: 0;
    font-weight: 500;
    border-bottom: 1px solid $panel-border-color;
    cursor: pointer;
    float: left;
    margin-right: 10px;
    background: $tab-background;
    outline: none;

    &.dragging {
      opacity: 0.3;
    }

    & > div {
      padding: 0.25em 1.1em 0.25em 0.1em;
      outline-color: $primary-color;
    }
  }

  &-tab:hover {
    color: $primary-highlight;
  }

  &-tab-active,
  &-tab-active:hover {
    color: $primary-color;
    cursor: default;
    transform: translateZ(0);
  }

  &-tab-disabled {
    cursor: default;
    color: $disabled-color;
  }

  &-tab-disabled:hover {
    color: $disabled-color;
  }


  &-content-animated {
    transition: margin .3s;
  }

  &-bottom, &-top {
    & > .dock-nav .dock-ink-bar-animated,
    & > div > .dock-nav .dock-ink-bar-animated {
      transition: width .3s, left .3s, right .3s
    }
  }


  &-content-animated &-tabpane {
    flex-shrink: 0;
  }

  .no-flexbox &-content {
    transform: none !important;
    overflow: auto;
  }


  &-tabpane:not(&-tabpane-active) {
    animation-name: dock-fadeOut;
    animation-duration: 0.5s;
  }

  // delay the hiding
  @keyframes dock-fadeOut {
    from {
      height: 100%;
      visibility: visible;
    }
    to {
      height: 100%;
      visibility: visible;
    }
  }

  &-top {
    flex-direction: column;
  }

  &-top &-bar {
    flex: 0 0 auto;
    background: $tab-background;
    border-bottom: 1px solid $nav-color-split;
    padding-left: 8px;
  }


  &-top &-content-animated {
    flex-direction: row;
  }


  &-top &-tab-next {
    right: 2px;
  }

  &-top &-ink-bar {
    height: 2px;
    bottom: 0;
    left: 0;
  }

  &-tab-close-btn {
    position: absolute;
    cursor: pointer;
    font-family: 'Fredoka One', sans-serif;
    color: $button-text-color;
    right: -3px;
    font-size: 12px;
    width: 16px;
    text-align: center;
    top: 6px;
    outline: none;
    transition: all 0.25s ease-in-out;

    &:before {
      content: "X";
    }
  }

  &-tab-close-btn:hover,
  &-tab-close-btn:focus {
    color: $button-text-focus-color;
    transform: scale(1.1, 1.1);
  }

  &-tab:hover &-tab-close-btn,
  &-tab-close-btn:focus {
    opacity: 1;
    color: $button-text-middle-color;
  }

  &-tab-hit-area {
    position: absolute;
    pointer-events: none;
    top: 0;
    bottom: 0;
    left: -5px;
    right: -5px;
  }

  &-pane-cache {
    width: 100%;
    height: 100%;
  }

  &-content {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &-content-holder {
    flex: auto;
  }
  &-top > &-content-holder {
    /* work around for the height issue in content holder*/
    height: 0;
  }

  &-content &-tabpane {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    outline: none;
    flex: none;
  }

  &-nav {
    display: flex;
    flex: none;
    position: relative;

    &-operations {
      display: flex;
    }

    &-more {
      height: 30px;
      border: none;
      color: $text-color;
      background: transparent;
      cursor: pointer;
      position: relative;
      align-self: flex-end;

      &::after {
        position: absolute;
        z-index: 1;
        content: '';
        pointer-events: none;
        width: 30px;
        height: 30px;
        left: -30px;
        top: 0;
        box-shadow: $nav-more-shadow;
      }
    }
  }


  &-nav-wrap {
    position: relative;
    display: flex;
    flex: auto;
    align-self: stretch;
    overflow: hidden;
    white-space: nowrap;
    transform: translate(0); // Fix chrome render bug
  }


  &-nav-list {
    display: flex;
    position: relative;
    transition: transform 0.3s;
  }

  &-extra-content {
    display: flex;
    height: 30px;
  }

  &-dropdown {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: $text-color;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
    display: block;

    &-hidden {
      display: none
    }

    &-menu {
      max-height: 200px;
      margin: 0;
      padding: 4px 0;
      overflow-x: hidden;
      overflow-y: auto;
      text-align: left;
      list-style-type: none;
      background-color: $dropdown-menu-bg;
      background-clip: padding-box;
      border-radius: 2px;
      outline: none;
      box-shadow: $box-shadow-base;
    }

    &-menu-item {
      min-width: 120px;
      margin: 0;
      padding: 5px 12px;
      overflow: hidden;
      color: $text-color;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      transition: all .3s;
      position: relative;

      & .dock-tab-hit-area {
        left: 0;
        right: 0;
      }

      & .dock-tab-close-btn {
        right: 2px;
      }
    }

    &-menu-item:hover {
      background: $item-hover-bg;
    }

    &-menu-item-disabled, &-menu-item-disabled:hover {
      color: $disabled-color;
      background: 0 0;
      cursor: not-allowed
    }
  }
}
body.dock-dragging .dock-tab-hit-area {
  pointer-events: inherit;
}

