@import "/src/styles/variables";

.redHeader {
    color: $red;
    padding: 0;
}

.greenHeader {
    color: $green;
    padding: 0;
}

.modalButton {
    padding: 0.5em 2em;
    margin: 0 1em;
}

.bold {
    font-weight: bold;
}