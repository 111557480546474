@import '/src/styles/variables';

.modal {
  background-color: $lightBg;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 0.1em solid rgba($mediumGrey, 0.5);
  box-shadow: 0 0 10px 1px rgba($mediumGrey, 0.5);
  border-radius: 0.1em;
  padding: 1em;
  max-height: 75vh;
}

.header {
  font-size: 1.3em;
  font-weight: 600;
  width: 100%;
  text-align: center;
  padding: 0.5em;
}

.content {  
  padding: 1em;
  padding-top: 0;
  overflow-y: auto;
  width: 100%;
}

.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0.5em;
}
// .notificationModal {
//   padding: 1em;
// }
// .notificationHeader {
//   padding: 0;
//   color: $yellow;
// }

.actionButton {
  margin: 0 0.3em;
}

.paddedContent {
  padding: 0 2em;
}


//
// notification modal
//

.notificationButtons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0.5em;
}

.notificationModal {
  background-color: $lightBg;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 0.1em solid rgba($mediumGrey, 0.5);
  box-shadow: 0 0 10px 1px rgba($mediumGrey, 0.5);
  border-radius: 0.1em;
  padding: 1em;
}
.notificationHeader {
  background-color: $lightBg;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 0.1em solid rgba($mediumGrey, 0.5);
  box-shadow: 0 0 10px 1px rgba($mediumGrey, 0.5);
  border-radius: 0.1em;
  padding: 1em;
  font-size: 1.3em;
  font-weight: 800;
}

.notificationFooter {
  padding-right: 1em;
  padding-left: 1em;
  padding-top: 1rem;
  padding-bottom: 1rem;

  background-color: $mediumGrey;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 0.1em solid rgba($mediumGrey, 0.5);
  box-shadow: 0 0 10px 1px rgba($mediumGrey, 0.5);
  border-radius: 0.1em;
}