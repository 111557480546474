
body.dock-dragging {
  user-select: none;
  -webkit-user-select: none;

  iframe {
    pointer-events: none;
  }
}

.dock-panel {
  box-sizing: border-box;
  position: relative;
  color: $text-color;
  background: $component-background;
  border: 1px solid $panel-border-color;

  &.dragging {
    opacity: 0.3;
  }

  &-drag-size {
    position: absolute;
    z-index: 300;
  }

  &-drag-size-t {
    top: -4px;
    left: 4px;
    right: 4px;
    height: 8px;
    cursor: ns-resize;
  }
  &-drag-size-b {
    bottom: -4px;
    left: 4px;
    right: 4px;
    height: 8px;
    cursor: ns-resize;
  }
  &-drag-size-l {
    left: -4px;
    top: 4px;
    bottom: 4px;
    width: 8px;
    cursor: ew-resize;
  }

  &-drag-size-r {
    right: -4px;
    top: 4px;
    bottom: 4px;
    width: 8px;
    cursor: ew-resize;
  }

  &-drag-size-t-l {
    top: -4px;
    left: -4px;
    width: 8px;
    height: 8px;
    cursor: nwse-resize;
  }

  &-drag-size-t-r {
    top: -4px;
    right: -4px;
    width: 8px;
    height: 8px;
    cursor: nesw-resize;
  }

  &-drag-size-b-l {
    bottom: -4px;
    left: -4px;
    width: 8px;
    height: 8px;
    cursor: nesw-resize;
  }

  &-drag-size-b-r {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3E%3Cpath fill='${panel-corner-color}' d='M2 12 L12 2 L12 12z'/%3E%3C/svg%3E");
    bottom: -4px;
    right: -4px;
    width: 17px;
    height: 17px;
    cursor: nwse-resize;
  }
}

.dock-box {
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
}

.divider-box {
  box-sizing: border-box;
  display: flex;
  align-items: stretch;

  & > *:nth-child(odd) {
    flex: 1 1 auto;
  }

}

.dock-divider {
  box-sizing: border-box;
  flex: 0 0 8px;
  background: rgba(0, 0, 0, 0);
  z-index: 1;
}

.dock-hbox {
  & > .dock-divider {
    // extend it to 12px, overlap the panel borders
    transform: scaleX(3);
    cursor: ew-resize;
  }
}

.dock-vbox {
  flex-direction: column;

  & > .dock-divider {
    // extend it to 12px, overlap the panel borders
    transform: scaleY(3);
    cursor: ns-resize;
  }
}

.dock-fbox {
  pointer-events: none;
  z-index: 200;

  & > .dock-panel {
    position: absolute;
    pointer-events: visible;
    box-shadow: $panel-box-shadow;
  }

  & > .dock-panel.dragging {
    opacity: 0.8;
    pointer-events: none;
  }
}

.dock-mbox {
  z-index: 250;
  width: 100%;
  height: 100%;

  & > .dock-panel {
    width: 100%;
    height: 100%;
    box-shadow: $panel-box-shadow;
  }

  &.dock-mbox-show {
    visibility: visible;
    animation: dock-mbox-show 0.2s ease;
  }

  &.dock-mbox-hide {
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    animation: dock-mbox-hide 0.2s ease;
  }
}

@keyframes dock-mbox-show {
  from {
    transform: scale(0.9, 0.9);
    visibility: visible;
  }
  to {
    transform: scale(1, 1);
    visibility: visible;
  }
}

@keyframes dock-mbox-hide {
  from {
    transform: scale(1, 1);
    visibility: visible;
    opacity: 1;
  }
  to {
    transform: scale(0.9, 0.9);
    visibility: visible;
    opacity: 0;
  }
}

.dock-wbox {
  position: absolute;
  width: 100%;
  height: 100%;

  & > .dock-panel {
    border: none;
    width: 100%;
    height: 100%;
  }
}

.dock-layout {
  overflow: hidden;
  position: relative;

  & > .dock-box {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  & > .dock-drop-indicator {
    position: absolute;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 400;
    border: solid 1px $drop-indicator-border-color;
    background: $drop-indicator-bg;
    opacity: 0.5;
    box-shadow: 0 0 4px $panel-border-color;
    display: none;
    transition: all 0.1s ease-out;
  }
}

.dock-drop-edge {
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: white;
  border: none;
  opacity: 0.01;
}

.dock-drop-layer {
  position: absolute;
  pointer-events: none;
  top: 30px;
  left: 0;
  right: 0;
  bottom: 0;

  .dock-drop-square {
    position: absolute;
    box-sizing: border-box;
    pointer-events: visible;
    z-index: 300;
    width: 32px;
    height: 32px;
    font-family: 'Fredoka One', sans-serif;
    color: $button-text-color;
    background: $component-background;
    border: 1px solid transparent;
    left: calc(50% - 16px);
    top: calc(50% - 16px);

    .dock-drop-square-box {
      box-sizing: border-box;
      border: 1px solid $panel-border-color;
      width: 100%;
      height: 100%;
    }

    &::before {
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      line-height: 30px;
      text-align: center;
    }
  }

  .dock-drop-tabs {

  }

  .dock-drop-float {
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    opacity: 0.01;
  }

  .dock-drop-left {
    left: calc(50% - 48px);

    &.dock-drop-deep {
      left: calc(50% - 64px);
      width: 16px;
    }

    &::before {
      content: '>';
      transform: rotate(180deg);
    }
  }

  .dock-drop-right {
    left: calc(50% + 16px);

    &.dock-drop-deep {
      left: calc(50% + 48px);
      width: 16px;
    }

    &::before {
      content: '>';
    }
  }

  .dock-drop-top {
    top: calc(50% - 48px);

    &.dock-drop-deep {
      top: calc(50% - 64px);
      height: 16px;

      &::before {
        line-height: 14px;
      }
    }

    &::before {
      content: '>';
      transform: rotate(270deg);
    }
  }

  .dock-drop-bottom {
    top: calc(50% + 16px);

    &.dock-drop-deep {
      top: calc(50% + 48px);
      height: 16px;

      &::before {
        line-height: 14px;
      }
    }

    &::before {
      content: '>';
      transform: rotate(90deg);
    }
  }

  .dock-drop-square-dropping {
    background: $drop-indicator-bg;
    color: white;
  }
}

.dock-panel.dock-panel-dropping .dock-tab-close-btn {
  pointer-events: none;
}

.dock-panel-max-btn, .dock-panel-min-btn {
  display: flex;
  align-items: center;
  height: 15px;
  width: 15px;
  font-family: 'Fredoka One', sans-serif;
  margin: 2px 2px 2px 0;
  padding: 6px 4px 4px 2px;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  outline: none;
  user-select: none;

  &:before {
    position: absolute;
    content: " ";
    border: 2px solid $button-text-color;
    border-radius: 1px;
    width: 16px;
    height: 16px;
  }

  &:hover, &:focus {
    color: $button-text-middle-color;

    &:before {
      border-color:$button-text-middle-color;
    }
  }
}

.dock-panel-min-btn {
  &:before {
    position: absolute;
    border-radius: 1px;
    width: 9px;
    height: 9px;
    border: none;
    content: "-";
    color: #666;
    font-size: 20px;
    line-height: 4px;
  }

  &:hover, &:focus {
    &:before {
      color: #999;
    }
  }
}
