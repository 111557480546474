@import '/src/styles/variables';

.select {
  border-radius: 0.1em;
  padding: 0.5em;
  width: 100%;
  background-color: $darkBg;
  border: 0.1em solid $white;
  color: $white;
  font-size: 1em;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  &:focus {
    outline: none;
  }
}
