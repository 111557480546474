@import "/src/styles/variables";

.qtyButton {
  margin-left: 0.25em;
  margin-right: 0.25em;
  border-radius: 5em;
  padding: 0;
  width: 2em;
  height: 2em;
  background-color: #565656 !important;
}

.qtyButtonActive {
  margin-left: 0.25em;
  margin-right: 0.25em;
  border-radius: 5em;
  padding: 0;
  width: 2em;
  height: 2em;
  background-color: #bababa !important;
  border-color: #bababa !important;
  color: black !important;
}

.followerAlert {
  width: 100%;
  height: 100%;
  position: absolute;
  // background: rgb(42 41 47 / 87%);
}

.followerInner {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.followerInnerText {
  padding: 8px;
  background-color: #00000088;
  box-shadow: 0 0 4px black;
  z-index: 1;
  border-radius: 6px;
  font-weight: 600;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

.domButton {
  margin: 0.25em;
  flex: 1 1;
  white-space: nowrap;
}

.joinButon {
  padding: 0.5em 2em;
  margin: 0.25em;
  flex: 1 1;
  white-space: nowrap;
}

.mainBoxNotMobile {
  display: inline-grid;
  width: 100%;
  position: sticky;
  justify-content: center;
  flex: 0 1 0;
  margin-top: 0.2em;
  margin-bottom: 0.5em;
}

.mainBoxMobile {
  width: 100%;
  position: sticky;
  justify-content: center;
}

.buttonBoxNotMobile {
  display: flex;
  // flex-direction: row;
  flex-wrap: wrap;
}

.buttonBoxMobile {
  display: flex;
  flex-direction: column;
}