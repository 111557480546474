// dock-style-place-holder
// when all the panels are floated we need a place holder in the dock box to handle drag over event
.dock-panel.dock-style-place-holder {
  border: none;

  .dock-bar {
    display: none;
  }
}

// dock-style-headless
.dock-layout > :not(.dock-fbox) {
  .dock-panel.dock-style-headless {
    border: none;

    .dock-bar {
      position: absolute;
      z-index: 1;
      opacity: 0;
      height: 20px;
      width: 100%;
      transition: all 0.15s ease-in-out;
    }

    .dock-bar:hover, .dock-bar:focus-within, &.dock-panel-dropping .dock-bar {
      opacity: 1;
      height: 31px;
    }

    .dock-content {
      height: 100%; // header size is 0
    }
  }
}

// dock-style-main
.dock-panel.dock-style-main {
  border: none;

  .dock-bar {
    background: none;
    border-bottom: 1px solid $nav-color-split-main;
  }

  .dock-tab {
    background: $component-background;
  }
}

//dock-style-card
.dock-panel.dock-style-card {

  .dock-tab {
    margin-right: 2px;
    border: 1px solid $panel-border-color;
    border-radius: 5px 5px 0 0;
  }

  .dock-tab.dock-tab-active {
    border-bottom: 1px solid $component-background;
    background: $component-background;
  }

  .dock-bar {
    border-bottom: 1px solid $panel-border-color;
    overflow: visible;
  }

  .dock-nav-wrap {
    padding-top: 5px;
    transform: translate(0, 1px);
  }

  .dock-ink-bar {
    /* hide animated ink bar */
    background: $component-background;
    opacity: 0;
  }

  .dock-tab-hit-area {
    /* cover the border area */
    left: -2px;
    right: -2px;
  }

  .dock-panel-max-btn, .dock-panel-min-btn {
    margin-top: 4px;
  }

  .dock-tab-close-btn {
    right: 0;
    font-size: 10px;
    top: 8px;
  }

  .dock-extra-content {
    height: 35px;
  }
}
