@import '/src/styles/variables';

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  justify-items: stretch;
  align-items: stretch;
  align-content: stretch;
  padding: 1em;
}

.contract {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  border-right: 1px solid $mediumGrey;
  &:first-child {
    border-left: 1px solid $mediumGrey;
  }
}

.contractName {
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
}
.desc {
  display: flex;
  align-content: stretch;
  color: #bbbbbb;
}
.full {
  flex: 1;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
}
.bias {
  display: flex;
  align-content: stretch;
  font-family: $numberFont;
}

.shortBias {
  color: $red;
}

.longBias {
  color: $green;
}

.disclaimerText {
  justify-content: center;
  display: flex;
  font-size: 0.9rem;
  color: #adadad;
}

.noTiltContainer {
  height: 100%;
  position: relative;
  top: 0px;
  left: 0px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.container {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
}

.column {
  flex: 1; /* Each column takes up an equal portion of the container */
  box-sizing: border-box;
}

.mobileColumn {
  min-width: 100%;
  margin-bottom: 0.7em;
  margin-top: 0.7em;
  font-size: 1.4em;
}

.blurred {
  filter: blur(5px);
}

.overlay {
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  z-index: 99999;
}
