.modalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(1px);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;

}

.modal {
    min-width: 20vw;
    min-height: 20vh;
    max-height: 70vh;
}