.mobileModal {
    height: 100vh;
    width: 100vw;
    padding: 2em;
}

.colorPickerOverlayBg {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    background: #3535355e;
    z-index: 10000;
}