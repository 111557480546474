@import '/src/styles/variables';

$activeBg: lighten($blue, 10%);
$selectedBg: lighten($blue, 15%);

.button {
  display: inline-block;
  font-family: $normalFont;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  font-size: 1em;
  border-radius: 0.25em;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: $blue;
  color: $white;
  font-weight: 600;
  border: 0.15em solid transparent;
  padding: 0.7em;
  text-transform: uppercase;

  &.fill {
    width: 100%;
  }

  &.small {
    font-size: 0.8em;
    padding: 0 0.3em;
  }

  &:hover {
    background-color: lighten($blue, 15%);
  }

  &:active {
    background-color: lighten($blue, 25%);
  }

  &.selected {
    background-color: lighten($blue, 15%);
    border-color: darken($white, 20%);
  }

  &.green {
    background-color: $green;
    &:hover {
      background-color: lighten($green, 15%);
    }
  }

  &.light {
    background-color: $mediumGrey;
    &:hover {
      background-color: lighten($mediumGrey, 15%);
    }
    color: $white;
  }

  &.outline {
    background-color: transparent;
    border-color: $mediumGrey;
    &:hover {
      background-color: rgba(0,0,0,0.1);
      color: $white;
    }
  }

  &.red {
    background-color: $red;
    &:hover {
      background-color: lighten($red, 15%);
    }
  }

  &.gold {
    color: $darkBg;
    &:hover {
      background-color: $mediumGrey;
      color: $white;
    }
  }

  &.grey {
    background-color: $darkBg;
    &:hover {
      background-color: lighten($darkBg, 15%);
    }

    &.selected {
      background: $activeBg;
    }
  }

  &:focus {
    outline: none;
  }

  &[disabled] {
    background-color: $darkBg;
    cursor: not-allowed;
    pointer-events: auto;
    color: gray;
   // border-color: $darkBg;

    &:hover {
      background-color: $darkBg !important;
      color: gray;
      pointer-events: auto;
      border-color: $darkBg !important;
    }
  }

  &[data-large='true'] {
    font-size: 1.25em;
    padding: 0 0.5em;
  }
}
