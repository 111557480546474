@import '/src/styles/variables';

.support {
  flex: 0;
  padding: 15px;
}

.supportButton {
  width: 100%;
  background: #24262c;
  color: #c5c5c5;
  border: solid 1px #373737;
}

.liveChatButton {
  width: 100%;
}