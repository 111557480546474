@import '/src/styles/variables';

.header {
  color: $lightGrey;
}

.liveEnvironmentContainer {
  height: 100%;
  position: relative;
  top: 0px;
  left: 0px;
  justify-content: center;
  display: flex;
  align-items: center;
}