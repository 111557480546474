.redCell {
  background-color: rgba(255, 0, 0, 0.1);
  color: #a5a5a5;
  border: solid 1px #434343;
}

.greenCell {
  background-color: rgba(0, 255, 0, 0.1);
  color: #a5a5a5;
  border: solid 1px #434343;
}

.cell {
  border: 1px solid #444444;
  color: #a5a5a5;
}

.cellOutside {
  position: relative;
}

.cellOutside::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #444444;
  background-color: #121212bb;
  z-index: 1;
}

.cell > *:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.greenCell > *:hover {
  background-color: rgba(0, 255, 0, 0.15) !important;
}

.redCell > *:hover {
  background-color: rgba(255, 0, 0, 0.15) !important;
}

.coach {
    padding: 20px;
    background: #2a292f;
    width: 100%;
    border-radius: 8px;
    font-size: 1.3rem;
    font-weight: 500;
    box-shadow: 2px 1px 2px #131314;
}