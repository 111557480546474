@import '/src/styles/variables';

.ping {
  color: $mediumGrey;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e6e8ef;
  font-weight: 800;
  font-family: $numberFont;

  @media (max-width: 1024px) {
    display: none !important;
  }
}