@import '/src/styles/variables';

.page {
  display: flex;
  flex-direction: row;
  height: 100%;

  .col1a {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .col1b {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .col2 {
    flex: 4;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .col3 {
    flex: 2;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .socialAndPodcast {
    display: flex;
    flex-direction: row;
  }

  .splitHorizontally {
    display: flex;
    flex-direction: row;
  }
  .time {
    background: $yellow;
  }

  .fit {
    flex: 1;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }
}
