// $theme-color-primary: #151616;
// $theme-color-secondary: #1d1f1f;

// $theme-color-highlight-text: rgb(224, 186, 17);
// $theme-color-primary-text: rgb(219, 219, 219);

// .form-control {
//   border-radius: 8px;
//   font-size: 1.2rem;
//   padding: 0.75rem;
//   line-height: 1.5;
//   background: $theme-color-secondary;
//   background-color: $theme-color-secondary;
//   color: $theme-color-primary-text !important;
//   height: initial;
//   border: none;
// }

// .form-control.is-invalid {
//   background: #2f2f2f;
// }

// .form-control:focus {
//   background: $theme-color-secondary;
//   background-color: $theme-color-secondary;
//   color: $theme-color-primary-text !important;
// }

// .main-bg {
//   background: $theme-color-primary;
// }

// .header {
//   font-size: 1.5em;
//   color: #ffee;
// }

// .login-butt {
//   background: #ebbc4e;
//   color: #000;
//   height: 40px;
//   width: 100%;
//   border: none;
//   font-size: 1rem;
// }

.early-access-text {
    display: flex;
    justify-content: end;
    margin-top: 0px;
    padding: 45px;
    padding-bottom: 0px;
    padding-top: 0px;
    color: #ebc468;
    font-weight: 100;
}

.forgot-pass-link {
    color: #c7c7c7!important;
}

.forgot-pass-link:hover {
    color: #fff!important;
}