@import '/src/styles/variables';

.balance {
  color: $mediumGrey;
  display: flex;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  background: $lightBg;
  padding: 0 1em;
  border-radius: 0.5em;
  width: fit-content;
  color: $white;
  white-space: nowrap;
  
  @media (max-width: 1024px) {
    display: none !important;
  }
}

.balanceMobile {
  color: $mediumGrey;
  display: flex;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  background: $lightBg;
  padding: 0 1em;
  border-radius: 0.5em;
  color: $white;
  flex-grow: 1;
}

.dll {
  color: $mediumGrey;
  display: flex;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  background: $lightBg;
  padding: 0 1em;
  border-radius: 0.5em;
  color: $white;

  @media (max-width: 1550px) {
    display: none !important;
  }
}

.dllMobile {
  color: $mediumGrey;
  display: flex;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  background: $lightBg;
  padding: 0 1em;
  border-radius: 0.5em;
  color: $white;
}

.balanceAmount {
  font-weight: 800;
  font-family: $numberFont;
}

.positive {
  background-color: $green;
}

.negative {
  background-color: $red;
}

.light {
  background-color: #2a292f;
}

.dark {
  background-color: #1c1e23;
}