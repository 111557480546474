@import '/src/styles/variables';

.number {
  font-family: $numberFont;
}

.currency {
  text-align: right;
}

.header {
  color: $lightGrey;
}

.removeButton {
  background-color: transparent !important;
}