@import '/src/styles/variables';
@import "./themes/gogo.dark.red.scss";

:root {
    --toastify-color-dark: #1c1e1ef0;
    
    // --toastify-toast-width: 320px;
    // --toastify-toast-background: $darkBg;
    // --toastify-toast-min-height: 64px;
    // --toastify-toast-max-height: 800px;
    // --toastify-font-family: sans-serif;
    // --toastify-z-index: 9999;
  
    // --toastify-text-color-light: #757575;
    --toastify-text-color-dark: #f8f8f8f0;
  }