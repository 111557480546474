@import '/src/styles/variables';

.heading {
  color: $white;
  padding: 0.5em 0;
}

.h1 {
  font-size: 1.65em;
  font-weight: 700;
}

.h2 {
  font-size: 1.45em;
  font-weight: 600;
}

.h3 {
  font-size: 1.35em;
  font-weight: 600;
}

.h4 {
  font-size: 1.2em;
  font-weight: 600;
}

.h5 {
  font-size: 1.1em;
  font-weight: 500;
}
