@import '/src/styles/variables';

.leader {
    color: $mediumGrey;
    display: flex;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
    background: #0d7ec0;
    padding: 0 1em;
    border-radius: 0.5em;
    color: #fff;
    font-weight: 600;
}

.follower {
    color: $mediumGrey;
    display: flex;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
    background: #c07a0d;
    padding: 0 1em;
    border-radius: 0.5em;
    color: #fff;
    font-weight: 600;
}