@import "/src/styles/variables";

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobileSelect {
  height: 24px !important;
}

.number {
  font-family: $numberFont;
}

.option {
  color: $white;
}

.actionIcon {
  cursor: pointer;
  color: $white;
  margin: 0 0.5em;
}

.newLayoutModalBody {
  display: flex;
  flex-direction: column;
  margin: "1em";
  padding: "1em";
}

.existingName {
  margin-top: 1em;
  color: $red;
}

.modalHeader {
  font-size: 1.2em;
  margin-bottom: 1em;
  text-align: center;
}
