.MuiDrawer-paper {
    background-color: #121212!important;
    background-image: none!important;
}

.quickButtonContainer {
    position: relative;
    margin-top: 5px;
    width: 100%;
    justify-content: space-evenly;
    display: flex;
    align-items: baseline;
}

.quickBuyButton {
    padding: 6px 9px;
    background: #43984d9c;
    border-radius: 8px;
    font-weight: bold;
}

.quickSellButton {
    padding: 6px 9px;
    background: #e74c3c9c;
    border-radius: 8px;
    font-weight: bold;
}