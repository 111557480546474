@import '/src/styles/variables';

.chartOrderContainer {
    position: absolute;
    top: 50px;
    right: 75px;
    display: flex;
    align-items: center;
    background: #2a292f8f;
    border-radius: 10px;
}

.domButton {
    margin: 0.25em;
    flex: 1 1;
    white-space: nowrap;
}

.qtyButton {
    margin-left: 0.25em;
    margin-right: 0.25em;
    border-radius: 5em;
    padding: 0;
    width: 2em;
    height: 2em;
    background-color: #565656 !important;
}

.quickButtonContainer {
    position: relative;
    //margin-top: 5px;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    display: flex;
}

.cardWrapper {
  height: 100%;
  width: 100%;
}

.tabWrapper {
  display: flex;
}

.tab {
  border: 1px solid $darkBg;
  flex: 1;
  text-align: center;
  cursor: pointer;
  padding: 0.5em;
}

.buyTab {
  &.selected {
    border-bottom: 0.15em solid $green;
  }
}

.sellTab {
  &.selected {
    border-bottom: 0.15em solid $red;
  }
}

.order {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 0 0.5em;
}

.orderTitle {
  display: flex;
  justify-content: space-evenly;
  // font-size: 1.1em;
  margin: 1em;
  // text-align: center;
}

.inputWrapper {
  display: flex;
  align-items: center;
  border: 0.1em solid $mediumGrey;
  border-radius: 5px;
  padding: 10p;
  padding: 0.5em;
  margin-bottom: 1em;

  &.range {
    flex-direction: column;
  }
}

.inputRange {
  flex: 1;
  margin: 0;
  width: 100%;
}

.rowDisplay {
  display: flex;
  flex-direction: row;
}

.inputTitle {
  flex: 0;
  white-space: nowrap;
  margin-right: 1em;
}

.inputContent {
  flex: 1;
}

.inputMarketContent {
  flex: 1;
  color: $mediumGrey;
}

.inputText {
  width: 100%;
  text-align: right;
  background: transparent;
  font-family: $numberFont;
  color: $white;
  border: none;
}

.inputAffix {
  white-space: nowrap;
  margin-left: 0.5em;
  width: 2em;
}


.marketOrderPriceContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.marketOrderPriceContainerBB {
  padding: 5px;
  border-radius: 0px 10px 10px 0px;
  font-weight: 100;
  border: solid 1px rgb(62 135 70 / .52);
  text-align: center;
}

.marketOrderPriceContainerLP {
  display: flex;
  justify-content: center;
  font-weight: 600;
  padding: 4px;
  text-align: center;
}

.marketOrderPriceContainerBA {
  padding: 5px;
  border-radius: 10px 0px 0px 10px;
  font-weight: 100;
  border: solid 1px rgb(140 46 43 / .52);
  text-align: center;
}

.marketOrderPriceContainerSpread {
  display: flex;
  justify-content: center;
  position: relative;
  top: -4px;
}

.accountCont {
  display: flex;
  justify-content: center;
}

.slideOutToggle {
  padding: 8px;
  background: #5f5f5ff5;
  font-weight: bold;
  position: fixed;
  top: 45vh;
  right: 0px;
  border-radius: 3px 0px 0px 3px;
  font-size: 1.2em;
  z-index: 999999;
}