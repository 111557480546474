// @import '~rc-dock/dist/rc-dock-dark.css';
@import '/src/styles/variables';
@import '/src/assets/css/sass/rc-dock/index-ts.scss';
$chat-padding-size: 60px;
// blue: $theme-color-primary: #122236;
// blue: $theme-color-secondary: #1d3048;

$theme-color-primary: #272727;
$theme-color-secondary: #1d1f1f;

$theme-color-highlight-text: #ffc700;
$theme-color-primary-text: rgb(219, 219, 219);

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;300;400;500;600;700;800;900&display=swap');
html {
  font-size: 12px;
}

body {
  letter-spacing: 0px;
  overflow: hidden;
  font-family: $normalFont;
  color: $white;
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: .9rem!important;
  background: $darkBg;
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;

  &.background {
    height: 100%;

    main {
      margin: 0 !important;
      height: 100%;

      .login .container {
        width: 100%important;
      }

      .container {
        height: 100%;
      }
    }
  }
}

#root {
  overflow: hidden;
}

.react-loading-skeleton {
  --base-color: #1d1f1f !important;
  --highlight-color: #151616 !important;
}

.top-head {
  // position: fixed;
  z-index: 9;
  width: 100%;
}

.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.status-ended {
  color: #f64e60;
  background-color: #3a2434;
  padding: 5px;
  border-radius: 5px;
  font-weight: 600;
}

.status-upcoming {
  color: #f5f5f5;
  font-weight: 600;
}

.status-in-progress {
  color: #0bb783;
  background-color: #1c3238;
  padding: 5px;
  border-radius: 5px;
  font-weight: 600;
}

.markets-closed {
  color: #635c5c;
  background-color: #502e2e;
  padding: 5px;
  border-radius: 5px;
  font-weight: 600;
}

.markets-open {
  color: #0bb783;
  background-color: #1c3238;
  padding: 5px;
  border-radius: 5px;
  font-weight: 600;
}

.selectedunit {
  background: #0bb783;
  color: #252525;
}

.toolbar {
  @media (max-width: 768px) {
    display: none !important;
  }
}

.trade-panel-overlay {
  position: fixed;
  top: 0px;
  background: #0a161e;
  padding: 10px;
  height: 100vh;
  width: 100vw;
}

.dollar-value {
  font-weight: 800;
}

.quote-description {
  color: #d4d4d4;
  font-weight: 700;
  font-size: 1.2rem;
}

.custom-border {
  border: solid 1px #262626;
}

.custom-box {
  background: #151616;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.mobile-toolbar {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 9999;
  padding-top: 0px;
  padding-bottom: 10px;
  background: $theme-color-secondary;
  @media (min-width: 768px) {
    display: none !important;
  }
}

.no-trading-overlay {
  width: 100vw;
  height: 92vh;
  background: rgba(14, 14, 14, 0.89);
  position: absolute;
  top: -70px;
  left: 0px;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 45px;
}

.futures-card {
  background: $theme-color-secondary;
  border: none !important;
  padding: 10px;
  border-radius: 15px;
  font-weight: normal !important;
}

.show-mobile-only {
  @media (min-width: 768px) {
    display: none !important;
  }
}

.admin-table th {
  font-weight: 300;
}

.flex-start-mobile {
  @media (max-width: 768px) {
    justify-content: flex-start !important;
  }
}

.short-carrot {
  background: #ffc700;
  border-radius: 50%;
  font-size: 0.9rem;
  color: black;
  height: 15px !important;
  width: 15px !important;
}

.long-carrot {
  background: #09ff00;
  border-radius: 50%;
  font-size: 0.9rem;
  color: black;
  height: 15px !important;
  width: 15px !important;
}

.mobile-heading {
  font-size: 1.2em;
  font-weight: 700;
  background: #323248;
  padding: 5px;
  margin-bottom: 10px;
}

.reset-button {
  background-color: rgb(113, 33, 33);
  padding: 6px;
  border-radius: 8px;
  text-align: center;
}

.reset-modal {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
  background: $theme-color-secondary;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.reset-account-button {
  background: $theme-color-highlight-text;
  border-radius: 20px;
  padding: 10px;
  margin-top: 20px;
  color: black;
}

.pulse {
  animation: pulse-animation 1.5s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(135, 144, 53, 0.15);
  }
}

.leaderboard {
  margin-right: 4px;
  padding: 0px 3px;
}

.leaderboard:first-of-type {
  background: #339944;
  color: #fff;
}

.quote-col {
  text-align: center;
  margin: 8px;
  cursor: pointer;
}

.quote-col:hover {
  background: #676767;
}

.selected-symbol {
  border: solid 2px #214571;
  background: #202020;
}

.pro-top-text {
  color: $theme-color-primary-text;
}

.symbol-card {
  padding: 20px;
  background: $theme-color-secondary;
  border-radius: 10px;
  text-align: center;
}

.time-and-pnl-bar {
  min-height: 54px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 24px;
  font-size: 1.4rem;
}

.countdown-container {
  display: flex;
  align-items: center;
  font-weight: 300;
  color: #dabf13;
}

.cancel-butt {
  background: $theme-color-secondary;
  color: rgb(219, 219, 219);
}

.add-trade-button {
  height: 45px !important;
  width: 45px !important;
  color: lighten($theme-color-secondary, 60);
}

.buy-button {
  padding: 12px;
  border-radius: 5px;
  font-weight: 800;
  background: #ffc700;
  color: #202020;
  cursor: pointer;
}

.sell-button {
  padding: 12px;
  border-radius: 5px;
  font-weight: 800;
  background: #725e18;
  color: #fff;
  cursor: pointer;
}

.disabled-button {
  background: #343434;
  color: #515151;
  pointer-events: none !important;
}

.path-container {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 10px;
  margin-left: 30px !important;
  color: #111111;
  background: $theme-color-highlight-text;
  margin-right: 30px !important;
  border-radius: 20px;
  padding: 20px;
}

.pending-order-button {
  color: #d9d9d9;
  height: 45px !important;
  width: 45px !important;
}

.modal-close-button {
  font-size: 3em;
  color: lighten($theme-color-secondary, 60);
}

.feed-button {
  background: #2f2f2f;
  padding: 10px;
  flex: 1;
}

.feed-button-active {
  background: darken($theme-color-highlight-text, 10);
  color: #fff;
}

.trade-box {
  color: #bababa;
  min-height: 75px;
  background: $theme-color-secondary;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3px 8px;
  border-radius: 10px 0px 0px 10px;
  width: 100%;
}

.active-bottom-button {
  color: $theme-color-highlight-text;
}

.completed-trade {
  background-color: darken($theme-color-secondary, 6);
  color: #979797;
}

.pro-header {
  padding: 8px 5px;
  background: $theme-color-secondary;
  border: solid 2px $theme-color-highlight-text;
  border-radius: 25px;
  color: $theme-color-highlight-text;
  font-weight: 500;
}

.bottom-pro-header {
  padding-top: 22px;
  width: 90%;
  color: white;
  font-weight: 300;
}

.position-admin-button {
  background: #272424;
  padding: 10px;
  cursor: pointer;
}

.position-admin-button-active {
  background: #505050;
}

.position-admin-button:hover {
  background: #505050;
}

.chart-button {
  background: #1a2021;
  padding: 4px;
  padding-bottom: 0px;
  padding-top: 0px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

.chart-button:hover {
  background: #101313;
}

.time-button {
  background: #1a2021;
  font-size: 0.7rem;
  align-items: center;
  display: flex;
  padding: 4px;
  padding-bottom: 0px;
  padding-top: 0px;
  border-radius: 5px;
  cursor: pointer;
}

.time-button:hover {
  background: #101313;
}

.dom-row {
  color: rgb(105, 105, 105);
  width: 100%;
  justify-content: center;
  margin: 0px;
  padding: 0px;
}

.qty-button {
  font-size: 0.8rem;
  background-color: rgb(105, 105, 105);
  justify-content: center;
  padding: 0px 3px 0px 3px;
  cursor: pointer;
}

.qty-button:active {
  border: none;
}

.cancel-flatten-button {
  font-size: 0.8rem;
  background-color: #565656 !important;
  justify-content: center;
  padding: 0px 3px 0px 3px;
}

.cancel-flatten-button:hover {
  background-color: #2c2c2c !important;
}

.timesales-row {
  width: 100%;
  color: #a5a5a5;
  justify-content: space-evenly;
}

.timesales-buy {
  color: #cc2525 !important;
}

.timesales-sell {
  color: #10ac10 !important;
}

.dom-ask:hover {
  cursor: pointer;
  border: solid 3px #cc2525 !important;
}

.dom-column {
  border: solid 0.5px #1e1e1e;
  border-bottom: none;
}

.go-long-market-button {
  background: #136713;
  border: none;
  color: #fff;
  font-size: 0.8rem;
  border-radius: 5px;
  cursor: pointer;
}

.go-short-market-button {
  background: #b61d1d;
  border: none;
  color: #fff;
  font-size: 0.8rem;
  border-radius: 5px;
  cursor: pointer;
}

.dom-bid-row {
  background-color: #151616;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: 'end';
  position: 'absolute';
}

.dom-volume-profile {
  background-color: #3a3a3a;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: 'start';
  position: 'absolute';
}

.dom-ask-row {
  background-color: #cc2525;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: 'start';
  position: 'absolute';
}

.dom-ask-row-darker {
  background-color: #b61d1d;
  color: #fff;
}

.dom-bid-row-color {
  background-color: #136713 !important;
  color: #fff;
}

.dom-bid-row-darker {
  background-color: #0f550f !important;
  color: #fff;
}

.dom-ask-order-cancel {
  cursor: pointer;
  background: #ac1b1b;
  justify-content: center;
  display: flex;
  width: 50%;
}

.dom-bid-order-cancel {
  cursor: pointer;
  background: #0f570f;
  justify-content: center;
  display: flex;
  width: 50%;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #151616;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4b4b4b;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4b4b4b;
}

iframe {
  background-color: #151616;
}

.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0px;
  right: 40vw;
  z-index: 999999;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
  bottom: auto;
}

.notification {
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
}

.notification-message {
  font-size: 0.8rem;
}

.qty-selected {
  border: solid 2px rgb(179, 179, 179);
}

.dom-bid:hover {
  cursor: pointer;
  border: solid 3px #136713 !important;
}

.quotes:nth-child(odd) {
  background: #1a1a1a;
}

.quotes:nth-child(even) {
  background: #1d1d1d;
}

.quotes:hover {
  background: #424242;
  cursor: default !important;
}

p {
  font-size: 1em;
}
.dock-drop-layer,
.dock-drop-square {
  color: rgb(27, 27, 27) !important;
  font-weight: 800;
  // border: 1px solid rgb(255, 255, 255) !important;
}

.dock-drop-square-box {
  background: #ebbc4e !important;
  color: rgb(20, 20, 20) !important;
  border: 1px solid rgba(0,0,0,0.5) !important;
}

.ql-snow .ql-picker {
  color: white !important;
}

.ql-formats > button > svg > * {
  stroke: white !important;
}

.ql-formats .ql-stroke {
  stroke: white !important;
}

.quill h1 {
  color: white !important;
}

.quill h2 {
  color: white !important;
}

.quill h3 {
  color: white !important;
}

.quill h4 {
  color: white !important;
}

.quill h5 {
  color: white !important;
}

.quill h6 {
  color: white !important;
}

.ql-picker-options > span {
  background-color: #373737;
}

.ql-picker-options {
  background-color: #373737 !important;
}

.ql-formats:first-child {
  display: none !important;
}

.ql-container .ql-editor {
  height: calc(100% - 40px) !important;
}

.ql-editor img {
  max-width: min(600px, 100%) !important;
}

.ql-video,
.ql-link {
  display: none !important;
}