.box {
    padding: 2em;
    margin-top: 1.2em;
    border-radius: 10px;
    box-shadow: 0 .25rem 1.25rem rgba(15,20,34,.75);
    background: #232227;
}

.mobileBox {
    padding: 2em;
    margin-top: 1.2em;
    border-radius: 10px;
}

.colorPickerOverlayBg {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    background: #3535355e;
    z-index: 10000;
}