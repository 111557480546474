$background-color: $darkBg;
$text-color: $white;
$dragging-layer-shadow: 0 0 8px rgba(255, 255, 255, 0.1), inset 0 0 8px rgba(255, 255, 255, 0.1);

$component-background: $lightBg;
$primary-color: $white;
$primary-highlight: mix(#fff, $primary-color, 20%);
$drop-indicator-bg: mix($component-background, $primary-color, 40%);
$drop-indicator-border-color: #fff;

$disabled-color: #ccc;
$panel-border-color: $lightBg;
$nav-color-split: $mediumGrey;
$nav-more-shadow: inset -10px 0 8px -8px fade(#000, 8%);
$tab-background: $lightBg;
$panel-corner-color: black;
$panel-box-shadow: 0 0 4px #444;

$button-text-color: #444;
$button-text-focus-color: #fff;
$button-text-middle-color: mix($button-text-color, $button-text-focus-color, 50%);

// main window style
$nav-color-split-main: $darkBg;

$dropdown-menu-bg: $component-background;
$item-hover-bg: mix(#fff, $dropdown-menu-bg, 10%);
$box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05);

@import './index';

.dock-tab {
  color: $mediumGrey;

  &.dock-tab-active {
    color: $white;
  }
}
