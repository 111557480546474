.table {
  width: 100%;

  tr {
    display: flex;
    flex-direction: row;

    td,
    th {
      flex: 3;
    }
  }
}

.accountSelector {
  margin-right: 1em;
  margin-left: 1em;
}

.accountWrapper {
  display: flex;
  flex-direction: row;
  justify-content: stretch;

  * {
    flex: 1;
  }

  margin-bottom: 2em;
  margin-top: 1em;
}

.warningBoxContainer {
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
}

.warningBox {
  border: solid 1px #a98c00;
  border-radius: 4px;
  background: #3b3b3b;
  padding: 5px;
  color: #e8e8e8;
  text-align: center;
}

.box {
  padding: 2em;
  margin-top: 1.2em;
  border-radius: 10px;
  box-shadow: 0 .25rem 1.25rem rgba(15,20,34,.75);
  background: #232227;
}