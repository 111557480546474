@import "/src/styles/variables";
$gridLineColor: rgba($mediumGrey, 0.2);

.modalButton {
  padding: 0.5em 2em;
  margin: 0 1em;
}

.bold {
  font-weight: bold;
}

.number {
  font-family: $numberFont;
}
.entryPriceCell {
  background: #4b4bff !important;
}
.notPosEntryPrice {
  border: 1px solid transparent !important;
}
.positionEntryPrice {
  border: 1px solid rgb(75, 75, 255) !important;
}

.pnlCell {
  display: flex;
}
.redHeader {
  color: $red;
  padding: 0;
}

.stopLoss {
  background: $red !important;
  color: $white;
}

.allowDrop {
  background: rgba($white, 0.5);
}

.takeProfit {
  background: $green !important;
  color: $white !important;
}

.sltpLongCenter {
  &:before {
    content: "";
    background: linear-gradient(to bottom, $green, $red);
    height: 120%;
    width: 0.3em;
    margin-right: 0.3em;
    z-index: 100;
    overflow: visible;
  }
}

.sltpShortCenter {
  &:before {
    content: "";
    background: linear-gradient(to bottom, $red, $green);
    margin-right: 0.3em;
    height: 120%;
    width: 0.3em;
    z-index: 100;
    overflow: visible;
  }
}

.bracketStopLossLine {
  &:before {
    content: "";
    background: $red;
    margin-right: 0.3em;
    left: 0;
    height: 120%;
    width: 0.3em;
    z-index: 100;
    overflow: visible;
  }
}

.topBracket {
  &:before,
  &:after {
    margin-bottom: -0.3em;
  }
}
.bottomBracket {
  &:before,
  &:after {
    margin-top: -0.3em;
  }
}

.entryPrice {
  &:after {
    margin-top: 0.1em;
    margin-bottom: 0.1em;
  }
}

.bracketTakeProfitLine {
  &:before {
    content: "";
    background: $green;
    left: 0;
    height: 120%;
    width: 0.3em;
    z-index: 100;
    overflow: visible;
  }
}

.leftBracketLine {
  &:before {
    content: "";
    left: 0;
    height: 120%;
    width: 0.3em;
    z-index: 100;
    overflow: visible;
  }
}

.leftBracketLine.greenLine {
  &:before {
    background: #136713;
  }
}

.leftBracketLine.redLine {
  &:before {
    background: #cc2525;
  }
}

.rightBracketLine {
  &:after {
    content: "";
    left: 0;
    height: 120%;
    width: 0.3em;
    z-index: 100;
    overflow: visible;
  }
}

.rightBracketLine.greenLine {
  &:after {
    background: #136713;
  }
}

.rightBracketLine.redLine {
  &:after {
    background: #cc2525;
  }
}

.greenHeader {
  color: $green;
  padding: 0;
}

.highDomRow {
  border-top: 0.2em solid $green !important;
}

.lowDomRow {
  border-bottom: 0.2em solid $red !important;
}

.domHeader {
  text-align: center;
  border-bottom: 0.2em solid $mediumGrey;
  font-size: 0.8em;
  width: 100%;
  justify-content: center;
}

.domRow {
  cursor: default;
  height: 100%;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  align-content: center;
  border-bottom: 1px solid $gridLineColor;
  font-family: $numberFont;
  color: $white;

  &:hover {
    background-color: transparentize($white, 0.9);
  }

  div {
    display: flex;

    align-items: center;
    border-right: 1px solid $gridLineColor;
    text-align: center;

    &:last-child {
      border-right: none;
    }

    width: 100%;
    height: 100%;
  }
}

.bidCell {
  flex-direction: row-reverse;
  cursor: pointer;
}

.bidCell:hover {
  cursor: pointer !important;
}

.priceCell {
  justify-content: center;
  font-weight: 300;
  border: 1px solid transparent;
  user-select: none;
}

.positionCell {
  border: 1px solid rgb(75, 75, 255) !important;
}

.askCell {
  justify-items: flex-start;
  cursor: pointer;
}

.askCell:hover {
  cursor: pointer !important;
}

.volume {
  text-shadow: 0 0 2px black;
  background: $mediumGrey;
}

.ask {
  color: $white;
  padding-left: 0.2em;
  text-shadow: 0 0 2px black;
}

.bid {
  align-self: left;
  font-weight: bold;
}

.bidAmount {
  position: absolute;
  color: $white;
  text-shadow: 0 0 2px black;
}

.center {
  color: rgb(22, 22, 22);
  background: #aeaeae;
  transition: all 0.1s ease-in-out;
}

.qtyButton {
  margin-left: 0.25em;
  margin-right: 0.25em;
  border-radius: 5em;
  padding: 0;
  width: 2em;
  height: 2em;
  background-color: #565656 !important;
}

.qtyButtonActive {
  margin-left: 0.25em;
  margin-right: 0.25em;
  border-radius: 5em;
  padding: 0;
  width: 2em;
  height: 2em;
  background-color: #bababa !important;
  border-color: #bababa !important;
  color: black !important;
}

.addRemoveButton {
  background: #064f7a;
  margin-left: 0.25em;
  margin-right: 0.25em;
}

.dom {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.joinButon {
  padding: 0.5em 2em;
  margin: 0.25em;
  flex: 1 1;
  white-space: nowrap;
}

.domButton {
  margin: 0.25em;
  flex: 1 1;
  white-space: nowrap;
}

.followerAlert {
  width: 100%;
  // height: 100%;
  position: absolute;
  background: rgb(42 41 47 / 87%);
}

.followerInner {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.followerInnerText {
  padding: 8px;
  background: #6b6b6b59;
  border-radius: 6px;
  font-weight: 600;
}

.accountCont {
  display: flex;
  justify-content: center;
  margin-top: 0.5em;
}

.buttonContainerMobile {
  padding: 1em;
}

.orderTypeSpan {
  display: none;
  font-size: 0.8em;
}

.askCancelButton {
  margin-right: 0;
}
.bidCancelButton {
  margin-left: 0;
}
@container orderText (width > 3.5em) {
  .bidCancelButton {
    margin-left: 0.3em;
  }
  .askCancelButton {
    margin-right: 0.3em;
  }
  .orderTypeSpan {
    display: inline-block;
  }
  /* <stylesheet> */
}

.orderTypeText {
  container: orderText / inline-size;
  //flex: 1;
  // span {
  //   display: block;
  // }
}
