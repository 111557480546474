body > .dragging-layer {
  position: absolute !important;
  z-index: 9999;
  overflow: visible;
  margin: 0;
  padding: 0;
  border: none;
  pointer-events: none;
  opacity: 0.6;
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
  --default-background-color: $component-background;
  /* dragging element */

  & > *:first-child {
    pointer-events: none !important;
    overflow: hidden !important;
    transform: translate(-50%, -50%) !important;
    box-shadow: $dragging-layer-shadow;
  }

  & > .dragging {
    /* prevent double translucent layers */
    opacity: 1 !important;
  }

  /* icon */

  & > div:last-child {
    position: absolute;
    left: 12px;
    top: 12px;

  }
}

.drag-initiator {
  user-select: none;
  -webkit-user-select: none;
  touch-action: none;
}

.drag-accept-reject {
  &::after {
    content: "🚫";
  }
}
