@import '/src/styles/variables';

.row {
  font-family: $numberFont;
}

.symbol {
  font-family: $normalFont;
  white-space: nowrap;
}

.numberText {
  font-weight: 800!important;
}

.header {
  color: $lightGrey;
}

.addButton {
  position: absolute;
  bottom: 40px;
  left: 10px;
  text-transform: none !important;
  font-size: 12px !important;
  background-color: #cfd4cd;
  color: #2a292f;
}

.addTitle {
  font-size: 12px;
  color: $lightGrey;
  margin-top: 8px;
  margin-bottom: 8px;
  text-align: center;
}

.removeButton {
  background-color: transparent !important;
}