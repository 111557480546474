@import '/src/styles/variables';

.settingsHeader {
    font-size: 1.6em;
    font-weight: 300;
    color: #d5d5d5;
    margin-left: 2px;
}

.settingsMenuCont {
    display: flex;
    width: 100%;
    height: 4em;
}

.settingsButton {
    color:#d1d1d1;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    display: flex;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
}

.settingsButton:hover {
    color: white;
    transition: color 0.3s ease;
}

.active {
    background-color: #4d4c51;
    font-weight: 600;
    color: #fff;
}

.box {
    padding: 2em;
    margin: 1.2em;
    border-radius: 10px;
    box-shadow: 0 .25rem 1.25rem rgba(15,20,34,.4);
}